import { useEffect, useState, useLayoutEffect } from 'react';
import breakpoints from '../utils/breakpoints';

interface IWindowSize {
	width: undefined | number;
	height: undefined | number;
}

const useBreakpoint = () => {
	const [breakpoint, setBreakPoint] = useState('');
	const [windowSize, setWindowSize] = useState<IWindowSize>({
		width: undefined,
		height: undefined,
	});

	const handleResize = () => {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	};

	useLayoutEffect(() => {
		window.addEventListener('resize', handleResize);
		handleResize();
	}, []);

	useEffect(() => {
		if (!windowSize.width) return;

		if (0 < windowSize.width && windowSize.width < 600) {
			setBreakPoint(breakpoints[0]);
		}
		if (600 < windowSize.width && windowSize.width < 960) {
			setBreakPoint(breakpoints[600]);
		}
		if (960 < windowSize.width && windowSize.width < 1280) {
			setBreakPoint(breakpoints[960]);
		}
		if (1280 < windowSize.width && windowSize.width < 1920) {
			setBreakPoint(breakpoints[1280]);
		}
		if (windowSize.width >= 1920) {
			setBreakPoint(breakpoints[1920]);
		}

		return () => window.removeEventListener('resize', handleResize);
	}, [windowSize.width, windowSize.height]);

	return breakpoint;
};

export default useBreakpoint;

export const useReportsPage = () => {
	const [isReportsPage, setIsReportsPage] = useState(true);
	const [isAnalyticsPage, setIsAnalyticsPage] = useState(true);

	useEffect(() => {
		const setIsAvaible = () => {
			setIsReportsPage(window.innerWidth >= 1199);
			setIsAnalyticsPage(window.innerWidth >= 790);
		};
		setIsAvaible();

		window.addEventListener('resize', setIsAvaible);

		return () => removeEventListener('resize', setIsAvaible);
	}, []);

	return { report: isReportsPage, analytics: isAnalyticsPage };
};

export const useCountPagination = () => {
	const [count, setCount] = useState(10);

	useEffect(() => {
		const setCountSize = () => {
			setCount(window.innerWidth >= 1199 ? 10 : window.innerWidth >= 800 ? 7 : window.innerWidth >= 700 ? 5 : 3);
		};

		setCountSize();
		window.addEventListener('resize', setCountSize);

		return () => removeEventListener('resize', setCountSize);
	}, []);

	return count;
};
