import React, { Fragment } from 'react';
import { IStoreState } from 'services/store/interfaces';
import { EventItem } from '../../EventsItems';
import styles from '../eventsAll.module.scss';
import { EventsList } from '../../EventsList';
import scs from '../NewEvents/newEvents.module.scss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const NotificationList = () => {
	const { t } = useTranslation();
	const readList = useSelector((state: IStoreState) => state.notifications.readNotifications);
	const unReadList = useSelector((state: IStoreState) => state.notifications.unReadNotifications);

	return (
		<Fragment>
			<p className={styles.heading}>
				{t('events.unread')} ({unReadList.length})
			</p>

			{unReadList.length > 0 ? (
				<EventsList>
					{unReadList.map((notification, index) => {
						const { MessageId, Timex, MessageText, MessageTypeId } = notification;

						return (
							<EventItem
								key={index}
								id={String(MessageId)}
								title={MessageText}
								description={MessageText}
								messageType={MessageTypeId}
								date={Timex}
								type="notifications"
								status={MessageTypeId}
							/>
						);
					})}
				</EventsList>
			) : (
				<div className={scs.empty}>{t('events.unreadEmpty')}</div>
			)}

			<p className={styles.heading}>
				{t('events.read')} ({readList.length})
			</p>

			{readList.length > 0 ? (
				<EventsList>
					{readList.map((notification, index) => {
						const { MessageId, MessageTypeId, Timex, MessageText, Checkx } = notification;

						return (
							<EventItem
								key={index}
								id={String(MessageId)}
								title={MessageText}
								description={MessageText}
								status={String(Checkx)}
								messageType={MessageTypeId}
								date={Timex}
								checkx="read"
								type="notifications"
							/>
						);
					})}
				</EventsList>
			) : (
				<div className={scs.empty}>{t('events.readEmpty')}</div>
			)}
		</Fragment>
	);
};
