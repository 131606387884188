import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { IStoreState } from 'services/store/interfaces';
import { Report } from '../Report';
import styles from './reports.module.scss';
import { useSelector } from 'react-redux';
import { variants } from '../animation';
import { Loading } from 'components/Common/Loading';

export const SalesReports = () => {
	const { saleReports } = useSelector((state: IStoreState) => state.analytics);

	return (
		<AnimatePresence>
			<motion.div {...variants} className={styles.reports}>
				{saleReports.length > 0 ? (
					saleReports.map((report) => <Report {...report} key={report.ReportType} />)
				) : (
					<Loading />
				)}
			</motion.div>
		</AnimatePresence>
	);
};
