import React from 'react';
import styles from './index.module.scss';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import {
	defaultListEvents,
	defaultListGoods,
	defaultListOrders,
	hideEventsFilter,
	hideGoodsFilter,
	hideOrderFilter,
	hideOrdersFilter,
	setFiltersEvents,
	setFiltersGoods,
	setFiltersOrders,
} from 'services/store/filter';
import { hideGoods, hideOrder, hideOrders } from 'services/store/setting';
import { Reports } from 'components/Content/Reports';
import { setAddressDefault } from 'services/store/address';
import { useTranslation } from 'react-i18next';

export type TFunc = () => void;
interface IMenuProps {
	isScrolled?: boolean;
	type?: string;
}

const NAVLINKS = [
	{ id: 1, title: 'pages.home', link: '/home', type: 'home' },
	{ id: 2, title: 'pages.orders', link: '/orders', type: 'orders' },
	{ id: 3, title: 'pages.goods', link: '/goods', type: 'goods' },
	{ id: 4, title: 'pages.events', link: '/events', type: 'events' },
	{ id: 5, title: 'pages.info', link: '/profile', type: 'profile' },
];

const SUPERLINKS = [
	{ id: 1, title: 'pages.home', link: '/home', type: 'home' },
	{ id: 5, title: 'pages.info', link: '/profile', type: 'profile' },
];

export const Menu = ({ isScrolled, type }: IMenuProps) => {
	const { t } = useTranslation();
	const ContractorName = localStorage.contractor ? JSON.parse(localStorage.contractor).ContractorName : '';
	const isAdmin = ContractorName === 'SUPPLIERSUPPORT';
	const withAnalytics = localStorage.withAnalytics === '1';

	const menuItems = isAdmin
		? SUPERLINKS
		: withAnalytics
		? [...NAVLINKS, { id: 6, title: 'pages.analytics', link: '/analytics', type: 'analytics' }]
		: NAVLINKS;

	const isSubmenu = type === 'submenu';
	const activeStyles = classNames({
		[styles.list_link__active]: !isScrolled,
		[styles.list_link__open]: isScrolled,
		[styles.list_link__submenu]: isSubmenu,
	});
	const defaultStyles = classNames(styles.list_link, { [styles.list_link__scroll]: isScrolled });

	const dispatch = useDispatch();

	const hideAllActions = () => {
		dispatch(hideOrdersFilter());
		dispatch(hideOrderFilter());
		dispatch(hideGoodsFilter());
		dispatch(hideEventsFilter());
		dispatch(hideOrders());
		dispatch(hideOrder());
		dispatch(hideGoods());
	};

	const redirectOrders = () => {
		dispatch(setFiltersOrders({ filters: defaultListOrders }));
		dispatch(setAddressDefault());
		hideAllActions();
	};

	const redirectGoods = () => {
		dispatch(setFiltersGoods({ filters: defaultListGoods }));
		hideAllActions();
	};

	const redirectEvents = () => {
		dispatch(setFiltersEvents({ filters: defaultListEvents }));
		hideAllActions();
	};

	const mapRedirectType = {
		orders: redirectOrders,
		goods: redirectGoods,
		events: redirectEvents,
		home: () => {},
		profile: () => {},
	};

	return (
		<nav className={classNames({ [styles.nav]: !isSubmenu, [styles.subnav]: isSubmenu })}>
			<ul className={styles.list}>
				{menuItems.length > 0 &&
					menuItems.map(({ id, title, link, type }) => (
						<NavLink
							onClick={mapRedirectType[type as keyof TFunc]}
							key={id}
							to={link}
							className={defaultStyles}
							activeClassName={activeStyles}
						>
							{t(title)}
						</NavLink>
					))}

				{!isSubmenu && !isAdmin && <Reports mix={defaultStyles} />}
			</ul>
		</nav>
	);
};
