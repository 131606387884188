import React, { FC } from 'react';
import styles from './download.module.scss';
import { getReport } from 'services/api/getReport';
import { getIsGzip, getJSON } from 'services/utils/helpers/getIsExcel';
import { exportGzip } from 'services/utils/js/exportToCSV';
import { TError } from 'services/store/interfaces';
import { useTranslation } from 'react-i18next';

interface IDownloadProps {
	ReportId: string;
	ReportName: string;
}
export const DownloadFile: FC<IDownloadProps> = ({ ReportId, ReportName }) => {
	const { t } = useTranslation();

	return (
		<div
			className={styles.download}
			onClick={(e) => {
				e.stopPropagation();

				getReport({ ReportId, ContractorId: localStorage.userId })
					.then((res) => res.blob())
					.then((blob) => {
						getIsGzip(blob)
							? exportGzip(blob, ReportName ? `${ReportName}` : `${t('analytics.report')}`)
							: getJSON(blob).then((err: TError) => console.log('error'));
					});
			}}
		>
			{t('analytics.download')}
		</div>
	);
};
