import React, { FC, useState } from 'react';
import { ReactComponent as CardSVG } from 'assets/images/analytics/card.svg';
import { ReactComponent as OkSVG } from 'assets/images/analytics/check.svg';
import { ReactComponent as RevenueSVG } from 'assets/images/analytics/revenue.svg';
import { IReport, TReportType, TStatus } from 'services/store/interfaces/analytics';
import { AnimatePresence, motion } from 'framer-motion';
import { variants } from '../animation';
import classNames from 'classnames';
import { Actions } from '../ReportActions';
import { generateReport } from 'services/api/generateReport';
import { useTranslation } from 'react-i18next';
import styles from './report.module.scss';
import { description, title } from '../texts';
import { getLeftToRightDate } from 'services/utils/js/getFilterDate';
import { getInfoReport } from 'services/store/analytics';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import dayjs from 'dayjs';

const IconSVG = ({ type, status }: { type: TReportType; status: TStatus }) => {
	return status === 3 ? <OkSVG /> : type === 5 ? <CardSVG /> : <RevenueSVG />;
};

const generate = async (type: TReportType, cb: (status: TStatus) => void) => {
	const result = await generateReport({
		ReportType: type,
		ContractorId: localStorage.userId,
		DateBegin: dayjs().format('YYYY-MM-DD'),
		DateEnd: dayjs().format('YYYY-MM-DD'),
	});

	if (result[0].Result) {
		cb(2);
	}

	if (result[0].Error) {
		cb(0);
	}

	return result[0];
};

export const Report: FC<IReport> = ({ Status, ReportType, DateEnd, DateBegin, ReportId }) => {
	const [reportStatus, setStatus] = useState<TStatus>(Status);
	const [loading, setIsLoading] = useState(false);
	const { generating } = useSelector((state: IStoreState) => state.analytics);
	const { t } = useTranslation();
	const dispatch = useDispatch();

	return (
		<div
			className={classNames(styles.report, {
				[styles.report__error]: reportStatus === 0,
				[styles.report__disabled]: reportStatus === 2 || ([0, 1, 4].includes(reportStatus) && generating),
				[styles.report__blocked]: reportStatus === 0 && generating,
			})}
		>
			<div className={styles.icon}>
				<IconSVG type={ReportType} status={Status} />
			</div>

			<div className={styles.name}>
				<div
					className={classNames(styles.title, {
						[styles.disabled]: [0, 1, 4].includes(Status) && generating,
					})}
				>
					{t(title[ReportType])}
				</div>

				<div className={styles.description}>{t(description[ReportType])}</div>
			</div>

			<div className={styles.action}>
				<Actions
					loading={loading}
					status={reportStatus}
					disabled={Status === 2 || generating}
					ReportType={ReportType}
					ReportId={ReportId}
					onGenerate={() => {
						setIsLoading(true);
						generate(ReportType, setStatus)
							.then(() => setIsLoading(false))
							.then(() => dispatch(getInfoReport({ types: [], ContractorId: localStorage.userId })));
					}}
				/>
			</div>

			<div
				className={classNames(styles.dates, {
					[styles.dates_generate]: Status === 2,
				})}
			>
				{t('filters.from')} {getLeftToRightDate(DateBegin)} {t('filters.to')} {getLeftToRightDate(DateEnd)}
			</div>

			<AnimatePresence>
				{reportStatus === 0 && !generating && (
					<motion.div className={styles.error} {...variants}>
						{t('analytics.try')}...
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};
