import React, { useState } from 'react';
import { fetchMergeOrderBuy } from 'services/store/orders';
import { ActionButton } from 'components/Common/ActionButton';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import { useTranslation } from 'react-i18next';
import { useLanguage } from 'services/contexts/languageContext';

export const ActionMerge = () => {
	const language = useLanguage();
	const { t } = useTranslation();
	const { activeOrders } = useSelector((state: IStoreState) => state.orders);
	const [inMerging, setInMerging] = useState(false);
	const dispatch = useDispatch();

	return (
		<ActionButton
			text={t('actions.combine')}
			type="merge"
			onClick={
				inMerging
					? () => {}
					: () => {
							setInMerging(true);
							dispatch(fetchMergeOrderBuy({ OrderBuyGroupId: activeOrders, Language: language === 'ru' ? 1 : 2 }));
					  }
			}
		/>
	);
};
