import React, { useEffect } from 'react';
import { MainLayout } from 'layouts/Main';
import { Analytics } from 'components/Content/Analytics';
import { getInfoReport } from 'services/store/analytics';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';

export const AnalyticsPage = () => {
	const { mode } = useSelector((state: IStoreState) => state.analytics);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getInfoReport({ types: [], ContractorId: localStorage.userId }));
	}, [mode]);

	return (
		<MainLayout>
			<Analytics />
		</MainLayout>
	);
};
