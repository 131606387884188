import { fetchAPI } from 'services/utils/fetchAPI';

interface ICheckId {
	contractorId: number | string;
}

interface IResult {
	Result: string;
	contractorId: string;
}

export const getCheckId = async (payload: ICheckId): Promise<IResult> => {
	return (await fetchAPI.post('checkid', { ...payload })) as unknown as IResult;
};
