import React, { useEffect, useRef } from 'react';
import { IStoreState } from 'services/store/interfaces';
import { useSelector, useDispatch } from 'react-redux';
import { SalesReports } from '../SalesReports';
import { MarketingReports } from '../MarketingsReports';
import styles from './reportsGroup.module.scss';
import { getInfoReport } from 'services/store/analytics';
import { Message } from '../../../Common/Message';

export const ReportsGroups = () => {
	const { mode, generating, empty } = useSelector((state: IStoreState) => state.analytics);
	const intervalRef = useRef<ReturnType<typeof setInterval> | undefined>();
	const dispatch = useDispatch();

	useEffect(() => {
		//когда отчет готов, удаляем таймер
		if (!generating) clearInterval(intervalRef.current);

		//когда отчет в процессе генерации, запускаем функцию на получение статусов по отчету
		if (generating) {
			intervalRef.current = setInterval(() => {
				dispatch(getInfoReport({ types: [], ContractorId: localStorage.userId }));
			}, 20000);
		}
	}, [generating]);

	useEffect(() => {
		return () => clearInterval(intervalRef.current);
	}, []);

	return (
		<div className={styles.group}>
			{empty ? (
				<Message text="analytics.empty" style={{ padding: 0 }} />
			) : (
				<>
					{mode === 1 && <SalesReports />}
					{mode === 2 && <MarketingReports />}
				</>
			)}
		</div>
	);
};
