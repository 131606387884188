import React from 'react';
import styles from './tabs.module.scss';
import { ReactComponent as SaleSVG } from 'assets/images/analytics/sales.svg';
import { ReactComponent as MarketingSVG } from 'assets/images/analytics/marketings.svg';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import { setMode } from 'services/store/analytics';
import { useTranslation } from 'react-i18next';

export const Tabs = () => {
	const { t } = useTranslation();
	const mode = useSelector((state: IStoreState) => state.analytics.mode);
	const dispatch = useDispatch();

	return (
		<div className={styles.tabs}>
			<div
				className={classNames(styles.tab, styles.tab_sales, {
					[styles.tab__active]: mode === 1,
				})}
				onClick={() => dispatch(setMode(1))}
			>
				<SaleSVG />
				<div className={styles.tab__text}>{t('analytics.sales')}</div>
			</div>

			<div
				className={classNames(styles.tab, styles.tab_marketings, {
					[styles.tab__active]: mode === 2,
				})}
				onClick={() => dispatch(setMode(2))}
			>
				<MarketingSVG />
				<div className={styles.tab__text}>{t('analytics.marketing')}</div>
			</div>
		</div>
	);
};
