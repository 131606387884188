import React from 'react';
import styles from './analytics.module.scss';
import { Tabs } from './Tabs';
import { ReportsGroups } from './ReportsGroups';

export const Analytics = () => {
	return (
		<div className={styles.analytics}>
			<Tabs />
			<ReportsGroups />
		</div>
	);
};
