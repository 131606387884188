import React, { Fragment, FC, useState } from 'react';
import styles from './actions.module.scss';
import { ReactComponent as DownloadSVG } from 'assets/images/analytics/download.svg';
import classNames from 'classnames';
import { getReport } from 'services/api/getReport';
import { useTranslation } from 'react-i18next';
import { getIsGzip, getJSON } from 'services/utils/helpers/getIsExcel';
import { exportGzip } from 'services/utils/js/exportToCSV';
import { TError } from 'services/store/interfaces';
import { TReportType } from 'services/store/interfaces/analytics';
import { description, title } from '../texts';
import { Points } from 'components/Common/Loading/Points';

interface IAction {
	status: number;
	onGenerate: () => void;
	ReportId?: string;
	ReportType: TReportType;
	loading: boolean;
	disabled: boolean | null;
}

export const Actions: FC<IAction> = ({ status, loading, disabled, onGenerate, ReportId, ReportType }) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(loading);

	return (
		<Fragment>
			{[0, 1, 4].includes(status) && (
				<div
					className={classNames(styles.button, {
						[styles.disabled]: disabled,
						[styles.error]: status === 0,
					})}
					onClick={
						disabled
							? () => {
									console.log('disabled');
							  }
							: onGenerate
					}
				>
					{isLoading && <Points style={{ marginRight: 15, marginLeft: -10 }} />}
					{t('analytics.generate')}
				</div>
			)}

			{status === 2 && (
				<div className={styles.generating}>
					<p>{t('analytics.generating')} ...</p>

					<div className={styles.generating_long}>{t('analytics.long')}</div>
				</div>
			)}

			{status === 3 && ReportId && (
				<>
					<div
						className={classNames(styles.button, styles.download)}
						onClick={
							isLoading
								? () => {}
								: () => {
										setIsLoading(true);

										getReport({ ReportId, ContractorId: localStorage.userId })
											.then((res) => res.blob())
											.then((blob) => {
												getIsGzip(blob)
													? exportGzip(
															blob,
															`${t(title[ReportType])}${
																t(description[ReportType]) !== '' ? ' ' + t(description[ReportType]) : ''
															}`
													  )
													: getJSON(blob).then((err: TError) => console.log('error', err));
											})
											.then(() => setIsLoading(false))
											.catch((err) => {
												console.log(err.message);
												setIsLoading(false);
											});
								  }
						}
					>
						{isLoading ? (
							<Points style={{ marginLeft: -5 }} />
						) : (
							<>
								<span className={styles.download__text}>{t('analytics.download')}</span>
								<DownloadSVG />
							</>
						)}
					</div>

					{isLoading && <div className={styles.generating_long}>{t('analytics.downloadText')}</div>}
				</>
			)}
		</Fragment>
	);
};
