import { useLayoutEffect, useState } from 'react';
import { getCheckId } from '../admin-api/getCheckId';
import { useLocation } from 'react-router';

export const useAnalyticsPage = () => {
	const { pathname } = useLocation();
	const [isAnalytics, setIsAnalytics] = useState(localStorage.withAnalytics === '1');

	useLayoutEffect(() => {
		const checkId = async () => {
			const result = await getCheckId({ contractorId: localStorage.userId });
			if (result.contractorId !== '' && result.Result === 'Ok') {
				localStorage.setItem('withAnalytics', '1');
				setIsAnalytics(true);
			} else {
				setIsAnalytics(false);
				localStorage.removeItem('withAnalytics');
			}
		};

		checkId().then(() => {});
	}, [pathname]);

	return isAnalytics;
};
