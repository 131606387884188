import React, { Fragment, FC, useState, useEffect } from 'react';
import styles from './list.module.scss';
import { Item } from '../Item';
import { Modal } from '../Modal';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState, TError } from 'services/store/interfaces';
import { AppDispatch } from 'services/store';
import { fetchCancelOrder, fetchConfirmOrder, setOrderExportMessage } from 'services/store/orders';
import { Points } from 'components/Common/Loading/Points';
import { getOrderBuyForExport } from 'services/api/GetOrderBuyForExport';
import { exportFile } from 'services/utils/js/exportToCSV';
import { getIsExcel, getJSON } from 'services/utils/helpers/getIsExcel';
import { ACTIONS, description, helps, text, title } from '../data';
import { TListProps, TModal, TTypes } from '../types';
import { removeObjKeys } from 'services/utils/helpers/removeObjKeys';
import { useTranslation } from 'react-i18next';
import { useLanguage } from 'services/contexts/languageContext';

export const List: FC<TListProps> = ({ onClose }) => {
	const language = useLanguage();
	const { t } = useTranslation();
	const { listOrders } = useSelector((state: IStoreState) => state.filter);

	const { messageOrder } = useSelector((state: IStoreState) => state.orders);
	const [modal, setIsModal] = useState<TModal>({ isModal: false, text: '', description: '' });
	const [isLoading, setIsLoading] = useState({ cancel: false, confirm: false, exporting: false });
	const [messageExport, setMessageExport] = useState(false);

	const { confirm, cancel, exporting } = isLoading;
	const inProcess = confirm || cancel || exporting;
	const dispatch = useDispatch<AppDispatch>();

	const onCloseList = (value: TModal) => {
		setIsModal({ ...value, isModal: true });
		onClose();
	};

	const onCloseModal = () => setIsModal({ text: '', description: '', isModal: false });

	const cancelOrders = () => {
		setIsLoading({ ...isLoading, cancel: true });

		dispatch(
			fetchCancelOrder({
				...removeObjKeys(listOrders, ['PageNumber', 'Limit']),
				OrderBuyGroupInfoId: '10',
				CancelType: '3',
				Language: language === 'ru' ? 1 : 2,
			})
		);
	};

	const confirmOrders = () => {
		setIsLoading({ ...isLoading, confirm: true });

		dispatch(
			fetchConfirmOrder({
				...removeObjKeys(listOrders, ['PageNumber', 'Limit']),
				ConfirmType: '3',
				Language: language === 'ru' ? 1 : 2,
			})
		);
	};

	const exportOrders = () => {
		setIsLoading({ ...isLoading, exporting: true });

		getOrderBuyForExport({ ...removeObjKeys(listOrders, ['PageNumber', 'Limit']), Language: language === 'ru' ? 1 : 2 })
			.then((res) => res.blob())
			.then((blob) => {
				getIsExcel(blob)
					? exportFile(blob, t('actions.exportOrders'))
					: getJSON(blob).then((err: TError) =>
							dispatch(setOrderExportMessage({ type: 'error', text: err.ErrorDescription }))
					  );
			})
			.then(() => setMessageExport(true))
			.then(() => setIsLoading({ ...isLoading, exporting: false }));
	};

	const handlerClick = () => {
		if (inProcess || !modal.type) return;

		const onClickMap = { cancel: cancelOrders, confirm: confirmOrders, export: exportOrders };
		onClickMap[modal.type]();
	};

	useEffect(() => {
		if (messageOrder.length !== 0) {
			setTimeout(onCloseModal);
			setTimeout(() => setIsLoading({ ...isLoading, cancel: false, confirm: false }));
		}

		if (messageExport) {
			setTimeout(onCloseModal);
			setTimeout(() => setIsLoading({ ...isLoading, exporting: false }));
			setMessageExport(false);
		}
	}, [JSON.stringify(messageOrder), messageExport]);

	return (
		<Fragment>
			<div className={styles.list}>
				{ACTIONS.map(({ id }) => {
					const type = id as TTypes;
					const modal = { type, text: t(title[type]), description: t(description[type]) };

					return (
						<Item
							key={type}
							text={t(title[type])}
							help={t(helps[type])}
							onClick={() => onCloseList(modal)}
							loading={type === 'export' ? exporting : undefined}
						/>
					);
				})}
			</div>

			{modal.isModal && (
				<Modal title={modal.text} description={modal.description} onClose={onCloseModal}>
					<div className={styles.close} onClick={onCloseModal}>
						{t('actions.close')}
					</div>

					<div className={styles.confirm} onClick={handlerClick}>
						{inProcess ? <Points style={{ margin: 5 }} /> : t(text[modal.type as TTypes])}
					</div>
				</Modal>
			)}
		</Fragment>
	);
};
