import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IAnalyticsState, IDTOInfoReport } from './interfaces/analytics';
import { infoReport } from 'services/api/infoReport';

const initialState: IAnalyticsState = {
	mode: 1,
	saleReports: [],
	marketingReports: [],
	generating: null,
	empty: false,
};

export const getInfoReport = createAsyncThunk('analytics/getInfoReport', async (data: IDTOInfoReport) => {
	return await infoReport(data);
});

const analytics = createSlice({
	name: 'analytics',
	initialState,
	reducers: {
		setMode: (state, action) => {
			state.mode = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getInfoReport.pending, () => {});
		builder.addCase(getInfoReport.fulfilled, (state, action) => {
			const data = action.payload;

			if (data[0].ReportType) {
				state.generating = action.payload.filter(({ Status }) => Status === 2).length > 0;
				state.saleReports = action.payload.filter(({ ReportType }) => [1, 2].includes(ReportType));
				state.marketingReports = action.payload.filter(({ ReportType }) => [3, 4, 5].includes(ReportType));
			}

			if (data[0].ResultDescription) {
				state.empty = true;
			}
		});
		builder.addCase(getInfoReport.rejected, (state) => {
			state.empty = true;
		});
	},
});

export const { setMode } = analytics.actions;

export default analytics.reducer;
