import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { CheckOffline } from './components/CheckOffline';
import { CheckMessage } from './components/CheckOffline/CheckMessage';
import { useHandlerMetrics } from './services/hooks/useHandlerMetrics';
import { LanguageProvider } from './services/contexts/languageContext';
import {
	ProfilePage,
	ErrorPage,
	LoginPage,
	OrdersPage,
	ReportsPage,
	GoodsPage,
	EventsPage,
	CookiesPage,
	HomePage,
	PolicyPage,
} from './pages';
import { useReportsPage } from './services/hooks/useBreakpoint';
import { useHiddenScroll } from './services/hooks/useHiddenScroll';
import { useAuthorised } from './services/hooks/useAuthorised';
import { AnalyticsPage } from './pages/AnalyticsPage';
import { useAnalyticsPage } from './services/hooks/useAnaliticsPage';

const App = () => {
	const isAuthorised = useAuthorised();

	const { report, analytics } = useReportsPage();
	const isAnalytics = useAnalyticsPage();

	useHiddenScroll();
	useHandlerMetrics();

	return (
		<LanguageProvider>
			<ScrollToTop />
			<CheckOffline />

			<Switch>
				<Route exact={true} path="/">
					{isAuthorised ? <Redirect to="/home" /> : <Redirect to="/login" />}
				</Route>

				<Route exact={true} path="/profile">
					{isAuthorised ? <ProfilePage /> : <Redirect to="/login" />}
				</Route>

				<Route exact={true} path="/login">
					{isAuthorised ? <Redirect to="/home" /> : <LoginPage />}
				</Route>
				{/*<Route exact={true} path="/registration">*/}
				{/*	{jwt ? <Redirect to="/home" /> : <Registration />}*/}
				{/*</Route>*/}
				<Route exact={true} path="/orders">
					{isAuthorised ? <OrdersPage /> : <Redirect to="/login" />}
				</Route>

				<Route exact={true} path="/orders/:id">
					{isAuthorised ? <OrdersPage /> : <Redirect to="/login" />}
				</Route>

				<Route exact={true} path="/reports/:id">
					{isAuthorised ? report ? <ReportsPage /> : <Redirect to="/orders" /> : <Redirect to="/login" />}
				</Route>
				<Route exact={true} path="/goods">
					{isAuthorised ? <GoodsPage /> : <Redirect to="/login" />}
				</Route>

				<Route exact={true} path="/events">
					{isAuthorised ? <EventsPage /> : <Redirect to="/login" />}
				</Route>

				<Route exact={true} path="/analytics">
					{isAuthorised ? (
						analytics && isAnalytics ? (
							<AnalyticsPage />
						) : (
							<Redirect to="/orders" />
						)
					) : (
						<Redirect to="/login" />
					)}
				</Route>

				<Route exact={true} path="/home">
					{isAuthorised ? <HomePage /> : <Redirect to="/login" />}
				</Route>

				<Route exact={true} path="/cookies">
					<CookiesPage />
				</Route>

				<Route exact={true} path="/policy">
					<PolicyPage />
				</Route>

				<Route path="*">
					<ErrorPage />
				</Route>
			</Switch>

			<CheckMessage text="inet.check" />
		</LanguageProvider>
	);
};

export default App;
