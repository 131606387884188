enum METHOD {
	GET = 'GET',
	POST = 'POST',
}

const transport = async <T>(method: string, methodName: string, data: Record<string, unknown> = {}): Promise<T> => {
	const path = `${process.env.REACT_APP_ADMIN_API_DOMAIN}/${methodName}`;

	const response = await fetch(path, {
		method,
		// headers: {
		// 	'content-type':'application/json;'
		// },
		body: JSON.stringify(data),
	});

	return await response.json();
};

export const fetchAPI = {
	get: transport.bind(this, METHOD.GET),
	post: transport.bind(this, METHOD.POST),
};
