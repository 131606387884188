import React, { CSSProperties } from 'react';
import styles from './message.module.scss';
import { useTranslation } from 'react-i18next';

interface IMessageProps {
	text: string;
	style?: CSSProperties;
}
export const Message = ({ text, style }: IMessageProps) => {
	const { t } = useTranslation();
	return (
		<div className={styles.message} style={style}>
			{t(text)}
		</div>
	);
};
