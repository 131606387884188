export const title = {
	1: 'analytics.revenue',
	2: 'analytics.reserves',
	3: 'analytics.revenue',
	4: 'analytics.revenue',
	5: 'analytics.cards',
};

export const description = {
	1: '',
	2: '',
	3: 'analytics.week',
	4: 'analytics.day',
	5: '',
};
