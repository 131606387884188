import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Report } from '../Report';
import { IStoreState } from 'services/store/interfaces';
import { useSelector } from 'react-redux';
import styles from '../SalesReports/reports.module.scss';
import { variants } from '../animation';
import { Loading } from 'components/Common/Loading';

export const MarketingReports = () => {
	const { marketingReports } = useSelector((state: IStoreState) => state.analytics);

	return (
		<AnimatePresence>
			<motion.div {...variants} className={styles.reports}>
				{marketingReports.length > 0 ? (
					marketingReports.map((report) => <Report {...report} key={report.ReportType} />)
				) : (
					<Loading />
				)}
			</motion.div>
		</AnimatePresence>
	);
};
